import React, { useEffect, useState } from 'react'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Meta from 'components/meta'
import Wrapper from 'components/wrapper'

import axios from 'axios'

const useMessage = () => {
  const [message, setMessage] = useState('')

  useEffect(() => {
    getMessage()
  }, [])

  const getMessage = async () => {
    try {
      const res = await axios.get('/api/hello-redis')
      setMessage(res.data.message)
    } catch (error) {
      console.error(error.message)
    }
  }

  return message
}

export default function HelloPage({ location }) {
  return (
    <Wrapper
      location={location}
      variant="page"
      className="w-100 mx-auto d-flex flex-column bg-body-dark"
    >
      <Meta title="Hello Redis" />
      <Row className="py-3 justify-content-center text-center bg-body-dark">
        <Col xs={12} sm={10} md={9} lg={8}>
          <h2>Redis Test</h2>
          <p className="fs-5 mb-3">{useMessage()}</p>
        </Col>
      </Row>
    </Wrapper>
  )
}
